import { GlobalPromotionMessageList } from '../components/GlobalPromotion/MessageList';

function GlobalPromotionMessagesRoute() {
  return <GlobalPromotionMessageList />;
}

export const Component = GlobalPromotionMessagesRoute;

export function clientLoader() {
  return null;
}
